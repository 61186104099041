.content-container.main-footer {
    float: left;
    width: 100%;
}
.footer-menu.footer-second {
    background-color: #ccdfee;
    padding: 0.3125em 0;
}
.visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
}
.menu--footer .menu {
    text-align: center;
    padding: 0;
    margin: 1.625em 0;
    background-color: transparent !important;
    list-style: none outside;
}
.menu--footer .menu-item {
    margin: 0 33px !important;
    padding: 0.75em 0;
    font-size: 16px !important;
}
.menu--footer a {
    text-decoration: none;
    text-transform: uppercase;
    color: #3f5d6f !important;
    font-weight: bold;
    letter-spacing: 2px;
}
.footer-third {
    background-color: #b2cfe5;
    padding: 1rem 0;
}
.footer-main-logos {
    text-align: center;
}
.footer-main-logos ul {
    padding: 0;
    margin: 0;
}
.footer-main-logos li {
    margin: 0 5%;
    display: inline-block;
    text-decoration: none;
}
.footer-main-logos p {
    font-size: 0.875em;
    color: #3f5d6f;
    margin: 1.5rem 0;
}
.footer-anchor {
    text-decoration: none;
    text-transform: uppercase;
    color: #3f5d6f !important;
    font-weight: bold;
    letter-spacing: 2px;
    cursor: pointer;
    padding: 0;

    // color: #fff;
    // padding: 0;
    // font-size: 14px;
    // font-weight: 700;
    // text-transform: uppercase;
    // cursor: pointer;
}
@media (min-width: 992px) {
    .menu--footer .menu-item {
        display: inline-block;
        margin: 0 5%;
    }
    .menu--footer .menu {
        max-width: 90%;
    }
    .menu--footer {
        padding: 0.5em 0;
    }
    .footer-main-logos img {
        max-width: 100%;
        width: auto;
    }
}