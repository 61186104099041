h2 {
  font-size: 30px;
  font-weight: 400;
  color: #0060A8;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.coach-checkbox {
  height: 32px;
  width: 32px;
  margin: 0;
  color: #279CF7 !important;
}

/* Fix for Safari */
.col-6, .col-md-6, .col-xs-6 {
  max-width: 49.75% !important;
}
.col-lg-4, .col-md-4 {
  max-width: 33% !important;
}
.col-md-8, .col-8  {
  max-width: 66.5% !important;
}
.col-md-11 {
  max-width: 91% !important;
}

.origin-label, .destination-label {
  font-size: 16px;
  color: #0060a8;
  font-weight: bold;
  text-transform: uppercase;
  padding: -2px 0 0 0;
}

.fares-destination {
  float: right;
  display: inline-block;
}
.mobile-label {
  float: left;
  display: inline;
  margin: -2px 0 2px 0;
}

@media only screen and (max-width: 991px) {
  .col-lg-4, .col-md-4 {
    max-width: 100% !important;
  }
  .col-6, .col-md-6, .col-xs-6 {
    max-width: 100% !important;
  }
  .col-md-8, .col-8  {
    max-width: 100% !important;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .fares-destination {
    float: left !important;
  }
  .buy-now {
    width: 100px;
    height: 30px;
    float: right;
  }
  .mobile-label {
    width: 90px;
    margin: 2px 0 0 0;
  }
  .view-ticket {
    width:343px !important;
    height: 630px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: center;
  }
  .main-ticket-area {
    padding: 0 !important;
    height: 530px !important;
  }
  .logo-img {
    display: none;
  }
  .barcode-img {
    text-align: center !important;
  }
  .barcode-img img {
    width: 285px !important;
    margin: 16px auto !important;
    padding: 0 !important;
  }
  .ticket-text, .ticket-expiration {
    display: none !important;
  }
  .print-select, .desktop-pagination {
    display: none;
  }
  .left-box .destination-name {
    border: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .ticket-pagination, .desktop-pagination {
    display: none !important;
  }
  .origin-label, .destination-label {
    display: inline;
  }
  .mobile-ticket-number {
    display: inline !important;
    color: #0077CC;
    font-size: 16px;
    min-width: 200px;
    margin: 0 auto;
    padding: 0;
  }
  .mobile-arrow {
    margin: -2px 16px 0 16px;
    display: inline-block !important;
  }
  .mobile-pagination {
    float: left;
    display: inline-block !important;
    width: 100%;
    height: 38px !important;
    padding-top: 2px;
    margin-left: 16px !important;
    text-align: center !important;
  }
  .the-pagination {
    width: 180px !important;
    height: 40px;
    margin: 0 auto !important;
    display: inline !important;
  }
  .print-icon-mobile {
    display: inline !important;
    float: right;
  }
  .print-icon-left, .print-icon-right {
    display: none !important;
  }
  .view-ticket h3 {
    font-size: 16px !important;
    color: #4C6070;
    font-weight: normal !important;
  }
}

.mobile-pagination, .mobile-ticket-number, .mobile-arrow {
  display: none;
}
.print-icon-left {
  float: left;
  margin: 0 15px 5px 0;
}
.print-icon-right {
  margin: 0 15px 5px 0;
}
.ticket-pagination {
  display: inline;
}
.the-pagination {
  width: 300px;
  height: 40px;
  text-align: center;
  margin: 16px auto;
}
.prev-button {
  margin: -3px 8px 0 0;
}
.next-button {

}
.div-pagination {
  width: 140px !important;
  margin-left: 18px !important;
  display: inline-block !important;
}
.pagination-ul {
  display: inline !important;
  margin: 0 auto !important;
  border: none !important;
  overflow: auto !important;
}
.round-numbers {
  border-radius: 15px !important;
}
.desktop-pagination {
  display: inline;
  cursor: pointer;
  color: #279cf7;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.coach-header {
  height: 75px;
}

ul.nav.navbar-nav li a:hover {
  color: #FFFFFF !important;
}

button.btn, button.btn:hover, button.btn:active, .btn-primary, .btn-primary:hover, .btn-primary:active {
  width: 260px;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #279CF7 !important;
  background-color: #279CF7 !important;
  color: #FFFFFF !important;
  margin: 8px auto;
  -webkit-tap-highlight-color: transparent !important;
}
button.btn-secondary, button.btn-secondary:focus, button.btn-secondary:hover, button.btn-secondary:active {
  background-color: #FFFFFF !important;
  color: #279CF7 !important;
  border: 2px solid #279CF7 !important;
  -webkit-tap-highlight-color: transparent !important;
}
button.btn-primary:disabled, button.btn-primary:disabled:hover {
  background-color: #A8A8A8 !important;
  color: #FFFFFF !important;
  border: 2px solid #A8A8A8 !important;
}
button.wide {
  width: 360px !important;
}
.section-padding {
  padding: 30px 0;
  float: left;
  width: 100%;
}

.inner-wrapper {
  padding: 30px 0;
}

.ticket-type .inner-table tr td.fare {
  text-align: left !important;
  font-weight: 700;
}
table.table.custom-table.ticket-type tr td {
  padding-left: 0;
  padding-right: 0;
}
table.table.custom-table.ticket-type .inner-table tr td {
  padding-left: 15px;
  padding-right: 15px;
}
table.table.custom-table.ticket-type .inner-table tr td:first-child {
  width: 44%;
}
.custom-table.ticket-type tr th.shoping-cart {
  color: #fff;
  background: #7ea5c3;
  text-align: left;
  padding-left: 25px;
}
.border0 {
  border: 0 !important;
}
.mt30 {
  margin-top: 30px !important;
}
.purchase-btn {
  background: #29a1fe;
  padding: 10px;
  width: 40%;
  display: block;
  color: #fff;
  text-transform: uppercase;
  margin: 30px auto;
}
.purchase-btn:hover {
  color: #fff;
  text-decoration: none;
}
.minus {
  margin-right: 35px;
}
.persion-count {
  margin-right: 35px;
  font-weight: 700;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none;
}
.backdrop.backdrop-visible {
  display: block
}

.spinner-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.spinner-bg:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.5);
  top: 0;
}

.error-field {
  border-color: #F0270C !important;
}

.error-msg {
  color: #FF4500;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-left: 30px !important;
  position: relative;
  font: 400 14px/18px Lato;
  overflow: hidden;
  min-height: 30px;
  display: flex;
  align-items: center;
}
.error-msg:before {
  position: absolute;
  content: "!";
  width: 20px !important;
  height: 20px !important;
  background: #FF4500;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold;
  left: 0;
  line-height: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: #279cf7;
  text-transform: none;
}

.printing-logo {
  float: left;
  width: 33%;
  margin-top: 30px;
  display: none;
}

.print-all-tickets {
  display: none;
}

.tickets-white-top, .tickets-white-bottom {
  width: 80px;
  height: 5px;
  border: 5px solid white;
  margin: 0 auto;
  display: none;
  position: absolute;
  left: 47%;
}
.tickets-white-top {
  top: -7px;
}
.tickets-white-bottom {
  top: 338px;
}

.form-group.float-label-control input::-webkit-input-placeholder {
  /* Edge */
  color: #8D8D8D !important;
  font-size: 18px;
}

.form-group.float-label-control input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8D8D8D !important;
  font-size: 18px;
}

.form-group.float-label-control input::placeholder {
  color: #8D8D8D !important;
  font-size: 18px;
}
@media (max-width: 991px) {
  .card-btn-group button.btn {
    width: 100%;
  }
  .coach-header {
    height: 50px;
  }
}

@media print {
  .printing-logo {
    display: inline-block;
    float: none !important;
  }
  .print-all-tickets, .origin-label-ticket, .destination-label-ticket, .passenger-type, .origin, .destination {
    display: block !important;
  }
  .origin-name, .destination-name {
    border-bottom: 1px solid #222222 !important;
  }
  .passenger-type {
    margin-left: 16px !important;
  }
  .ticket-title {
    color: #2E2E2E !important;
  }
  .ticket-expiration {
    float: right !important;
    display: inline-block !important;
    padding-top: 30px !important;
  }
  .tickets-white-top, .tickets-white-bottom {
    display: inline;
  }
  .section-padding, .col-md-12, .col-md-6, .main-ticket-area {
    float: none !important;
  }
  .barcode-img, .logo-img {
    padding: 0;
    margin: 0 auto;
  }
  .logo-img img {
    margin: -8px 0 0 0 !important;
  }
  .barcode-img img {
    margin: -16px 0 0 0 !important;
  }
  .barcode-text {
    font-size: 14px !important;
    margin: 4px 0 0 60px !important;
  }
  #div_coach_account,
  .main-header,
  .no-print,
  .profile-area,
  .btn,
  .btn-primary,
  .btn-secondary,
  .expand-icon,
  .ticket-text,
  .ticket-expiration,
  .passenger-type-mobile,
  .ticket-expiration-mobile,
  nav,
  footer
  {
    display: none !important;
  }
}

  .fares-destination {
    float: right;
    display: inline-block;
    padding-left: 15%;
  }


//Header
@import "Header/Header.scss";



//Loader
@import "Loader/Loader.scss";


//ForgotPassword
@import "ForgotPassword/ForgotPassword.scss";

//UserProfile
@import "UserProfile/UserProfile.scss";

//ErrorPage
@import "ErrorPage/ErrorPage.scss";


//Footer
@import "Footer/Footer.scss";


