@import "~bootstrap/scss/bootstrap";
html{
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url(/img/mario-bg.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #EDEFF0;
  min-height: 100%;
}
.root {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  height: 100%;
}

.search{
  background-color: white;
}

.header-anchor{
  color:blue;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer
}

form{
  padding: 20px;
  margin-top: 60px;
}

form button, form h5{
  margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #ec407a;
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #ec407a !important;
}

 /*-- Custom Modal Popup --*/
 .modal-backdrop.show {
  opacity: 0.54;
  background: #848484;
}
.custom-modal-popup {
  opacity: 1;
}
.custom-modal-popup-email .modal-dialog {
  width: 50px;
  height: 800px;
  max-width: 800px;
  background-color:blue;
  color: white;
  margin: auto;
  border: 1;
  border-radius: 6px;
  opacity: 1;
  background: #fff;
  top: 15%;
}

.custom-modal-popup .modal-dialog {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 6px;
  opacity: 1;
  background: #fff;
  top: 15%;
}
.custom-modal-popup .modal-content {
  border: 0;
  background: transparent;
  border-radius: 6px;
  padding: 15px;
}

.modal-footer{
  justify-content: center !important;
  border:transparent;
  
}

.custom-modal-popup .modal-header {
  border: 0;
  padding: 0;
}
.non-save{
  color:#29A1FE;
  background-color: transparent;
  border-color: #007bff;
}
.modal-header{
  justify-content: flex-end;
}
.custom-modal-popup button.close {
  font-size: 40px;
  line-height: 22px;
  text-shadow: none;
  opacity: 1;
  color: #29A1FE;
}
.custom-modal-popup .modal-body {
  padding: 20px 30px;
  text-align: center;
}

// // Modal
// .orbit-custom-modal {
//   z-index: 999999997;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   outline: 0;
//   background: #000;
//   border: 0;
//   transition: 1.1s ease-out;
//   filter: blur(0);
//   transform: scale(1);
//   opacity: 0.75;
//   display: inline;
//   &.off {
//     opacity: 0;
//     visibility: hidden;
//     filter: blur(8px);
//     transform: scale(0.33);
//     box-shadow: 1rem 0 0 rgba(black, 0.2);
//   }
// }
// .orbit-modal-dialog {
//   z-index: 999999998;
//   position: absolute;
//   top: 100px;
//   margin: 0 auto;
//   left: 30%;
//   width: 40%;
//   height: auto;
//   border: 1px solid #e3e3e3;
//   background-color: #FFFFFF !important;
//   border-radius: 29px;
//   padding: 10px 35px 35px 35px;
//   display: inline;
//   opacity: 1;
// }
// .orbit-modal-content {
//   z-index: 999999999;
//   padding: 1rem;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 0;
//   outline: 0;
//   margin: 30px auto 0 auto;
//   opacity: 1;
// }
// .orbit-modal-content p {
//   text-align: center;
//   font: 400 31px/36px Lato;
//   color: #279CF7;
//   max-width: 75%;
//   margin: 0 auto;
// }

@media only screen and (max-width: 991px) {
  .orbit-modal-dialog {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: auto;
    border: 1px solid #e3e3e3;
    background-color: #FFFFFF !important;
    border-radius: 15px;
    padding: 10px;
  }
  button.wide {
    font-size: 12px !important;
    width: 300px !important;
  }
}

.confirm-account .devider {
  width: 80px;
  height: 2px;
  background: #279CF7;
  margin: 40px auto;
}
.confirm-account button.save-change {
  background: #279CF7;
  border-radius: 25px;
  text-align: center;
  font: 700 16px/38px Lato;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  width: 360px;
  margin: 0 auto 20px auto;
  border: 0;
}
.confirm-account button.save-change:hover,
.confirm-account button.save-change:active {
  background-color: #279CF7 !important;
  border: 0;
  outline: none;
  box-shadow: none !important;
}
.confirm-account button.non-save {
  width: 360px;
  margin: 0 auto 25px auto;
  background: transparent;
  text-align: center;
  font: 700 16px/36px Lato;
  letter-spacing: 0.4px;
  color: #279CF7;
  border:2px solid #279cf7;
  text-transform: uppercase;
}



// Orbit Pages
@import "../pages/coachusaOrbit/style/index.scss";

.form-control {
  padding: 24px 10px !important;
}

.custom-table tr th,
.custom-table tr td {
  text-align: center;
  border: 0;
  vertical-align: middle;
}
.custom-table tr th {
  color: #0060a9;
  font-size: 22px;
  font-weight: 700;
}
tr.light-blue th {
  color: #ffffff;
}
.padding0 {
  padding: 0 !important;
}

.modal-backdrop {
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 0 !important;
  width: 100vw;
  height: 100vh;
  background-color: transparent !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .inner-wrapper {
    padding: 0;
  }
}

/*-- Mobile --*/
@media (max-width: 767px) {
  .inner-wrapper {
    padding: 0;
  }
}
