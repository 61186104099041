.forgot-pass .form-control-placeholder {
    font: 400 14px/16px Lato;
    letter-spacing: 0;
    color: #1982C6;
    margin-left: 8px;
    margin-bottom: 0;
}
.forgot-hint {
    float: left;
    width: 100%;
    margin: 5px 0 15px 0;
    color: #263238;
    font: 400 18px/16px Lato;
    line-height: 150%;
}
.forgot-pass-box {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.forgot-pass-box h3 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 25px;
}
.relative img.password {
    margin-bottom: -10px;
}
.forgot-pass {
    position: relative;
}


@media (max-width: 767px) {
    .forgot-pass h1, .forgot-pass .forgot-hint {
        text-align: left;
    }
  }