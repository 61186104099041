.error-wrapper {
    background-color: #87C2ED;
    padding: 70px 0;
    background-size: cover;
    min-height: 800px;
    margin-top: -40px;
  }
  .error-wrapper h2 {
    font: 300 60px/60px Lato;
    letter-spacing: 0;
    color: #FFFFFF;
    margin-bottom: 25px;
  }
  .error-wrapper h3 {
    font: 700 44px/50px Lato;
    letter-spacing: 0;
    color: #FFFFFF;
    margin-bottom: 25px;
  }
  .error-wrapper h4 {
    font: 400 18px Lato;
    letter-spacing: 0;
    color: #FFFFFF;
    margin-bottom: 25px;
  }
  .white-devider {
    width: 110px;
    height: 8px;
    background: #fff;
    margin-bottom: 25px;
  }
  button.return-btn {
    min-width: 225px;
    border-radius: 25px;
    border: 0;
    background-color: #fff;
    padding: 15px;
    font: 900 14px/20px Lato;
    letter-spacing: 0;
    color: #239AF4;
    text-transform: uppercase;
  }
  button.return-btn:hover, button.return-btn:active {
    border: 0;
    background-color: #fff !important;
    font: 900 14px/20px Lato;
    color: #239AF4 !important;
    outline: none !important;
    box-shadow: none;
  }
  .error-content {
    font: 400 22px/30px Lato;
    letter-spacing: 0;
    color: #0060A8;
    margin: 30px 0;
  }