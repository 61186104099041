.user-profile {
  padding: 0;
  border: 0;
}
.user-profile-inner {
  padding-left: 30px;
}
.profile-navtab {
  padding: 0 37px;
}
.profilee-navtab a {
  font: 600 28px/30px Lato;
  letter-spacing: 0;
  //color: #0060A8;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  border-bottom: solid 1px #B5D6EF;
  margin-bottom: 15px;
}
.user-profile-inner label.form-control-placeholder.form-label {
  font: 600 14px/12px Lato;
  letter-spacing: 0;
  color: #239AF4;
  margin-left: 0;
}
// .user-profile-inner .form-control {
//   border: 1px solid #C8C8C9;
//   font: 700 18px/36px Lato;
//   color: #4C6070;
//   padding: 10px;
//   min-height: 50px;
//   box-shadow: none;
//   border-radius: 4px;
// }
.user-profile-inner .form-group {
  margin-bottom: 8px;
}
.btn.btn-save.btn {
  background-color: #7DA4C1;
  border: 0;
  padding: 15px;
  min-height: 58px;
  border-radius: 29px;
  min-width: 356px;
  margin: 15px auto 0 auto;
  display: block;
  text-align: center;
  font: 900 16px/24px Lato;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  outline: none;
}

/*-- MyTickets Start --*/

.nav.nav-tabs .past-tab a.nav-link, 
.nav.nav-tabs .current-tab a.nav-link {
  background-color: #ccdfee;
  font: 700 14px/30px Lato;
  letter-spacing: 0;
  color: #0060A8;
  text-transform: uppercase;
  padding: 10px 9px 9px;
  border: 0;
}
.nav.nav-tabs .past-tab a.nav-link {
  border-radius: 0px 22px 0px 0px;
}
.nav.nav-tabs .current-tab a.nav-link {
  border-radius: 30px 0px 0px 0px;
}
.nav.nav-tabs .past-tab a.nav-link.active, 
.nav.nav-tabs .current-tab a.nav-link.active {
  background-color: #0060A8;
  color: #FFFFFF;
}
.innertab {
  border: 4px solid #0060A8;
}
.single-row {
  padding: 15px 20px;
  border-bottom: solid 2px #ccdfee;
}
.single-row:last-child {
  border: 0;
}
.single-row .left-content {
  font: 400 16px/20px Lato;
  color: #263238;
}
.single-row .left-content a {
  font: 400 16px/20px Lato;
  color: #279CF7;
}
.single-row .right-content {
  font: 400 16px/30px Lato;
  color: #263238;
  text-align: right;
}
.single-row .right-content a, .coach-link {
  font: 400 14px/24px Lato;
  color: #1982C6;
  text-decoration: underline;
  cursor: pointer;
  display: block;
}
.remaining {
  font: 400 14px/20px Lato;
  color: #2E2E2E;
}

/*-- MyTickets End --*/
  //   padding: 0;
  //   border: 0;
  // }
.profileModal {
  display: block !important;
  opacity: 1;
}
.user-profile-inner {
  padding-left: 30px;
}
.profile-navtab {
  padding: 0 37px;
}
.profile-navtab a {
  font: 600 28px/30px Lato;
  letter-spacing: 0;
  color: #0060A8;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  border-bottom: solid 1px #B5D6EF;
  margin-bottom: 15px;
}
.profile-navtab a:hover {
  text-decoration: none;
  color: #0060A8 !important;
}
.profile-navtab a.active.current {
  border-bottom: solid 8px #0060A8;
}

  
  .user-profile-inner label.form-control-placeholder.form-label {
    font: 600 14px/12px Lato;
    letter-spacing: 0;
    color: #239AF4;
    margin-left: 0;
  }
  // .user-profile-inner .form-control {
  //   border: 1px solid #C8C8C9;
  //   font: 700 18px/36px Lato;
  //   color: #4C6070;
  //   padding: 10px;
  //   min-height: 50px;
  //   box-shadow: none;
  //   border-radius: 4px;
  // }
  .user-profile-inner .form-group {
    margin-bottom: 8px;
  }
  .btn.btn-save.btn {
    background-color: #7DA4C1;
    border: 0;
    padding: 15px;
    min-height: 58px;
    border-radius: 29px;
    min-width: 356px;
    margin: 15px auto 0 auto;
    display: block;
    text-align: center;
    font: 900 16px/24px Lato;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    outline: none;
  }
  .heading{
    color:#1592E6;
    font-size: 22px;
  }

  /*-- Custom Modal Popup --*/
.modal-backdrop.show {
  opacity: 0.54;
  background: #848484;
}
.custom-modal-popup {
  opacity: 1;
}
.custom-modal-popup .modal-dialog {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 6px;
  opacity: 1;
  background: #fff;
  top: 15%;
}
.custom-modal-popup .modal-content {
  border: 0;
  background: transparent;
  border-radius: 6px;
  padding: 15px;
}

.modal-footer{
  justify-content: center !important;
  border:transparent;
  
}

.custom-modal-popup .modal-header {
  border: 0;
  padding: 0;
}
.non-save{
  color:#29A1FE;
  background-color: transparent;
  border-color: #007bff;
}
.modal-header{
  justify-content: flex-end;
}
.custom-modal-popup button.close {
  font-size: 40px;
  line-height: 22px;
  text-shadow: none;
  opacity: 1;
  color: #29A1FE;
}
.custom-modal-popup .modal-body {
  padding: 20px 30px;
  text-align: center;
}
.payment-success h3 {
  font: 400 28px/36px Lato;
  letter-spacing: 0;
  color: #1592E6;
  margin-bottom: 30px;
}
.payment-success h4 {
  font: 400 28px/36px Lato;
  letter-spacing: 0;
  color: #263238;
  margin: 30px 0;
}
.gray-devider {
  background: #C1C7CC;
  height: 2px;
  width: 70%;
  margin: 30px auto;
}

.credit-card-poup h4 {
  font: 400 22px/20px Lato;
  letter-spacing: 0;
  color: #1592E6;
  margin-top: 0;
}

.credit-card-poup h3 {
  font: 400 28px/36px Lato;
  letter-spacing: 0;
  color: #1592E6;
  margin-bottom: 10px;
}

.custom-modal-popup h4 {
  text-align: center;
  letter-spacing: 0;
  color: #3c3c3c;
  font: 400 18px Lato;
}
button.btn.save-change, button.btn.non-save {
  min-width: 150px;
}
.error-msg-clone {
  padding: 30px;
  text-align: center;
  color: #223c63;
  font-weight: 700;
}


@media (max-width: 767px) {
  .profile-navtab {
    padding: 0;
  }
  .user-profile-inner {
    padding: 0;
  }
  .single-row .left-content {
    min-width: 100%;
    margin-bottom: 10px;
    padding: 0 5px;
  }
  .single-row .right-content {
    text-align: left;
    min-width: 100%;
    padding: 0 5px;
  }
  .nav.nav-tabs .past-tab a.nav-link {
    border-radius: 0px 10px 0px 0px;
  }
  .nav.nav-tabs .current-tab a.nav-link {
    border-radius: 10px 0px 0px 0px;
  }
  .mPadding0 {
    padding: 0;
  }
  button.btn-primary.btnn.dark-button {
    padding: 14px 15px;
    min-width: 150px;
    margin-right: 10px;
  }
  button.btn-primary.btnn.light-button {
    padding: 13px 15px;
    min-width: 150px;
    margin-right: 0;
  }
}