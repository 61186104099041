.main-logo {
    float: left;
    width: 285px;
    padding: 15px 0;
}

#div_coach_account {
  margin-top: 12px;
  float: right;
  display: inline-block;
}
.profile-area.basket-area {
  padding: 15px 0 0;
}

@media screen and (max-width: 991px) {
  .main-logo {
    width: 160px !important;
    padding: 15px 0;
  }
  #div_coach_account {
    margin-top: 0;
    position: absolute;
    top: 7px;
    right: 50px;
  }
  .section-padding {
    margin-top: 66px !important;
  }
}
  .profile-area {
    float: right;
    padding: 10px 0;
    position: relative;
  }
  .main-navigation {
    float: left;
    width: 100%;
    background: #0060A8;
  }
  .profile-area .basket {
    width: 55px;
    height: 55px;
    border: 0;
    padding: 0;
    background: transparent;
    position: relative;
    top: -3px;
  }
  .profile-area .basket .nav-link-count {
    position: absolute;
    background: #DD182A;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    font-size: 11px;
    color: #fff;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    right: -5px;
    top: 5px;
  }
  .user-name-box img {
    margin-right: 0;
    width: 25px;
  }
  .profile-user-name {
    background: transparent;
    border: 0;
    color: #0060A8;
    font-weight: 900;
    font-size: 14px;
    margin-right: 8px;
    line-height: 25px;
  }
  .signup-button:hover {
    color: #239AF4;
    text-decoration: none;
  }
  .user-name-box {
    margin-top: 12px;
    float: left;
  }
  .mt20 {
    margin-top: 12px;
  }
  .main-menu ul.navbar-nav li.nav-item {
    padding: 10px 13px;
  }
  .main-menu ul.navbar-nav li a.nav-link {
    color: #0060a9;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
  }
  .main-header {
    width: 100%;
    float: left;
    position: fixed;
    background-color: #FFFFFF;
    z-index: 1;
  }
  .section-padding {
    margin-top: 100px;
  }
.main-header .navbar-light {
    padding: 10px 0;
  }
  .main-menu ul.navbar-nav li.nav-item span a {
    color: #0060a9;
    font-weight: 600;
    font-size: 14px;
  }
  
  .main-menu ul.navbar-nav li.nav-item span a.basket {
    background-repeat: no-repeat;
    width: 20px;
    height: 30px;
    color: #fff;
    text-align: center;
    float: right;
    margin-left: 10px;
    background-size: 100%;
    font-size: 12px;
    padding-top: 0;
    position: relative;
    margin-top: -6px;
  }
  .popupbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
  }
  .login-button {
    color: #ffffff;
    font-weight: 900;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    position: relative;
    text-align: center;
    background: #239AF4;
    border: solid 2px #239AF4;
    border-radius: 25px;
    padding: 8px 20px;
    min-width: 122px;
    text-transform: uppercase;
    margin-right: 15px;
    margin-top: 4px;
  }
  .signup-button {
    color: #239AF4;
    font-weight: 900;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    position: relative;
    text-align: center;
    border: solid 2px #239AF4;
    border-radius: 25px;
    padding: 8px 20px;
    min-width: 122px;
    text-transform: uppercase;
    margin-right: 10px;
    display: inline-block;
    margin-top: 4px;
    background: transparent;
  }
  .logout-button {
    background: transparent;
    border: 0;
    color: #0060A8;
    font-weight: 900;
   
    font-size: 14px;
    margin-right: 10px;
    line-height: 25px;
  }
  
  .main-menu.navbar-nav a.navbar-nav.mr-auto.nav-link {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    padding: 0;
  }
  .main-menu.navbar-nav a.navbar-nav.mr-auto.nav-link a {
    color: #fff;
  }
  
  header.main-header .navbar form span a {
    color: #0060a9;
    font-weight: 600;
    font-size: 14px;
    padding: 1px 6px;
  }
  
  nav.navbar.navbar-default {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    min-height: auto;
  }
  ul.nav.navbar-nav {
    display: block;
  }
  ul.nav.navbar-nav li {
    display: inline-block;
    padding: 10px 40px;
  }
  ul.nav.navbar-nav li a {
    color: #fff;
    padding: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
    background-color: transparent;
}
  ul.nav.navbar-nav li:first-child {
    padding-left: 0;
  }
  ul.nav.navbar-nav li:last-child {
    padding-right: 0;
  }
  ul.nav.navbar-nav li a:hover {
    color: #fff;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .hidden-xs {
    display: block;
  }
  .hidden-lg {
    display: none;
  }

.header-anchor{
  color: #fff;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer
}
  
  /*-- Mobile device --*/
  @media (max-width: 991px) {
    .hidden-xs {
      display: none;
    }
    .hidden-lg {
      display: block;
    }
    .navbar-collapse.collapse {
      display: none !important;
  }
    button.navbar-toggle {
      display: block;
  }
    .main-navigation {
        background: transparent;
        margin-top: 0;
        border-top: solid 25px #0060A8;
    }
    .navbar-header {
      width: 100%;
      margin: 0 !important;
  }
  nav.navbar.navbar-default {
    padding: 0;
    min-height: 0;
  }
  ul.nav.navbar-nav li {
    display: inherit;
    padding: 20px 10px;
    border-bottom: solid 1px #b1b1b1;
    float: left;
    width: 100%;
  }
  ul.nav.navbar-nav li:first-child {
    padding-left: 10px;
  }
  ul.nav.navbar-nav li:last-child {
    padding-right: 10px;
  }
  ul.nav.navbar-nav li a {
    color: #239AF4;
  }
  ul.nav.navbar-nav li a:hover {
    color: #239AF4;
  }
  button.navbar-toggle {
    position: absolute;
    right: 15px;
    top: 7px;
    color: #DD182A;
    font-size: 23px;
    text-transform: uppercase;
    padding: 0;
    margin-right: 0;
  }
  .navbar-toggle .icon-bar {
    display: block;
    width: 40px;
    height: 3px;
    border-radius: 1px;
    background: #DD182A;
  }
  .navbar-toggle .icon-bar+.icon-bar {
    margin-top: 8px;
  }
  .navbar-collapse {
    border: 0;
  }
  ul.nav.navbar-nav {
    margin-top: 0;
    padding: 0 10px;
  }
  .profile-area {
    padding: 0 40px 0 0;
  }
  .main-header {
    padding: 0;
  }
  .profile-area .basket {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  .profile-area .basket .nav-link-count {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    right: 0;
    top: 0;
  }
  .user-name-box img {
    width: 20px;
    margin-right: 0;
  }
  .user-name-box {
    margin-top: 5px;
    text-align: center;
    line-height: 12px;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 10px;
  }
  .user-name-box a {
    color: #0060a8;
  }
  .main-logo {
    width: 150px;
    margin: 0;
  }
  .navbar-collapse {
    flex-basis: inherit;
    flex-grow: inherit;
    align-items: inherit;
    padding: 0;
    width: 100%;
  }
  .profile-area.basket-area {
    float: left !important;
    display: block !important;
    padding: 0;
    margin-left: 10px;
}
  }
