.loader {
  position: fixed;
  z-index: 9999;
  background: rgba(218, 218, 218, 0.5) !important;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
}
.loading-message {
  position: relative;
  margin: 100px auto;
  text-align: center;
  color: #425164;
}

.spinner {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: absolute !important;
  text-align: center;
  left: 50%;
  bottom: 50%;
  -webkit-animation: inherit !important;
  animation: inherit !important;
}




/*-- Coach Loader CSS --*/

.coach-loader {
  text-align: center;
  padding: 30px 0;
}
.coach-loader h3 {
  font: 400 32px/50px Lato;
  letter-spacing: -1.09px;
  color: #0060A8;
  margin: 20px 0 30px;
}
.coach-loader h4 {
  font: 700 18px/50px Lato;
  letter-spacing: 0;
  color: #0060A8;
  text-transform: uppercase;
  margin: 40px 0 0 0;
}
